import firebase from 'firebase';

import { USERS_COLLECTION } from './constants';

const getUser = async ({ userTungaID }) => {
	const db = firebase.firestore();

	try {
		const doc = await db.collection(USERS_COLLECTION).doc(userTungaID).get();
		if (doc.exists) {
			return doc.data();
		}
		return null;
	} catch (error) {}
};

const getSearchParams = (urlString) => {
	const url = new URL(urlString);
	return Object.fromEntries(url.searchParams.entries());
};

const getNearExpiredIds = (ids, days) => {
	const today = new Date();
	const projectedDate = new Date();
	projectedDate.setDate(today.getDate() + days);

	return ids.filter((id) => {
		if (id.id_status && id.id_status.expiry_date !== null) {
			try {
				const expirationDate = id.id_status?.expiry_date.toDate();
				if (expirationDate <= projectedDate && expirationDate >= today) {
					return true;
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Invalid date format', error);
			}
		}
	});
};

export { getUser, getSearchParams, getNearExpiredIds };
