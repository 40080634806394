export const BASE_API = process.env.REACT_APP_baseApi;
const OTP_BASE = process.env.REACT_APP_otpApi;

export const LOGIN_URL = `${BASE_API}/auth/token/`;
export const REFRESH_LOGIN_URL = `${BASE_API}/token/auth/refresh/`;
export const PDF_API = `${BASE_API}/auth/passport/pdf`;

export const RESET_PASSWORD = `${OTP_BASE}/auth/otp/`;
export const VERIFY_OTP = `${OTP_BASE}/auth/otp-verify/`;

export const ID_MANAGEMENT_URL = `${BASE_API}/auth/id-management/`;
export const USER_ID_URL = (tunga_id) =>
	`${BASE_API}/auth/id-management/${tunga_id}/`;

/** Local storage keys */
export const AUTH_TOKENS = 'BU#G#&#&#^&@(BDBmdfje7383839BDHDU#*37390s3';
export const EMAIL_KEY = 'FUEFGUEFG#&#^$*##&GDUGSU';
